<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('User')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Login User Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required|alpha-num"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="userData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                    disabled
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Password -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="password"
                rules="alpha-num|min:8"
              >
                <b-form-group
                  :label="$t('Password')"
                  label-for="password"
                >
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="userData.password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="getValidationState(validationContext)"
                      name="password"
                      placeholder=""
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

              </validation-provider>
            </b-col>

            <!-- Field: Role -->
            <b-col
              cols="12"
              md="4"
            >
              <!-- User Role -->
              <validation-provider
                #default="validationContext"
                name="role"
                rules="required"
              >
                <b-form-group
                  :label="$t('Role')"
                  label-for="role"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="userData.role_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roleOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="role"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required|email"
              >
                <b-form-group
                  :label="$t('Email')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  :label="$t('Status')"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="userData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="status"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Login Attempt -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="login-attempt"
                rules="required"
              >
                <b-form-group
                  label="Change to Unlocked if Locked"
                  label-for="login_attempt"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="userData.login_attempt"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="laOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="login_attempt"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="cancel"
            >
              <feather-icon icon="XIcon" />&nbsp;
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import router from '@/router/index'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {

  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'user-list' })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      { label: 'Admin', value: 1 },
      { label: 'Sale', value: 2 },
      { label: 'Store', value: 3 },
      { label: 'Manager', value: 4 },
      { label: 'Audit', value: 5 },
    ]

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ]

    const laOptions = [
      { label: 'Locked', value: 5 },
      { label: 'Unlocked', value: 0 },
    ]

    const blankUserData = {
      name: '',
      password: null,
      role_id: null,
      email: '',
      status: null,
      login_attempt: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const userId = router.currentRoute.params.id
    store.dispatch('user/fetchOne', { id: userId })
      .then(response => {
        const responseUserData = {
          id: response.data.data.id,
          name: response.data.data.name,
          role_id: response.data.data.role_id,
          email: response.data.data.email,
          status: response.data.data.status,
          login_attempt: response.data.data.login_attempt,
        }
        userData.value = responseUserData
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(userData.value))
      store.dispatch('user/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'user-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      userData,
      onSubmit,
      roleOptions,
      statusOptions,
      laOptions,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
